import React from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

function Navbar() {
  return (
    <nav className="navbar">
      <Link to="/" className="navbar-item">
        Home
      </Link>
      <Link to="/about" className="navbar-item">
        About
      </Link>
      <Link to="/books" className="navbar-item">
        Books
      </Link>
      <Link to="/blog" className="navbar-item">
        Blog
      </Link>
      <Link to="/contact" className="navbar-item">
        Contact
      </Link>
    </nav>
  );
}

export default Navbar;
