import React from "react";
import "./Contact.css";

function Contact() {
  return (
    <div className="HomePage">
      <div className="HomePage-header">
        <header class="UnderConstruction-header">
          <h1>Coming Soon</h1>
        </header>
        <section class="UnderConstruction-content">
          <p>This page is currently under construction. Stay tuned!</p>
        </section>
      </div>
    </div>
  );
}

export default Contact;

/*
import React from "react";
import "./Contact.css";

function Contact() {
  const sendEmail = (e) => {
    e.preventDefault(); // Prevents default form submission behavior
    // Collect form data
    const formData = {
      name: document.getElementById("name").value,
      // Add other fields as necessary
    };

    // Send a POST request to your server
    fetch("http://localhost:5000/send-email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => console.log(data))
      .catch((error) => console.error("Error:", error));
  };

  return (
    <div className="HomePage">
      <div className="ContactPage-header">
        <div className="contact-container">
          <h1>Contact Me</h1>
          <form id="contact-form" onSubmit={sendEmail}>
            <label htmlFor="name">Name:</label>
            <input type="text" id="name" name="name" required />

            <label htmlFor="email">Email:</label>
            <input type="email" id="email" name="email" required />

            <label htmlFor="message">Message:</label>
            <textarea id="message" name="message" required></textarea>

            <button type="submit">Send</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
*/
