import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import App from "./App.js";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import {
  HomePage,
  About,
  Books,
  Blog,
  Contact,
} from "./components/index.jsx";

// https://reactrouter.com/en/main/routers/create-browser-router
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />, // Use the App component for all routes
    children: [
      { path: "", element: <HomePage /> },
      { path: "about", element: <About /> },
      { path: "books", element: <Books /> },
      { path: "blog", element: <Blog /> },
      { path: "contact", element: <Contact /> },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
