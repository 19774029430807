import React from "react";
import "./Books.css";

function Books() {
  return (
    <div className="HomePage">
      <div className="HomePage-header">
        <header class="UnderConstruction-header">
          <h1>Coming Soon</h1>
        </header>
        <section class="UnderConstruction-content">
          <p>This page is currently under construction. Stay tuned!</p>
        </section>
      </div>
    </div>
  );
}

export default Books;
