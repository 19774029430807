import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./components/common/header/Header";
import "../src/assets/styles/base.css";
import "../src/assets/styles/utilities.css";

/** Assembles header/navigation bar with page based on current route */
const App = () => (
  <>
    <Header />
    <main>
      <Outlet /> {/* Renders the current route's component */}
    </main>
  </>
);

export default App;
