import React from "react";
import "./Header.css";
import Navbar from "../navbar/Navbar";
import icon from "../../../assets/images/icon.png";

const Header = () => {
  return (
    <header className="header">
      <img src={icon} width="80" height="80" alt="logo" />
      <Navbar />
    </header>
  );
};

export default Header;
