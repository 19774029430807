import React from "react";
import stars from "../assets/images/stars.jpg";
import book from "../assets/images/book.jpg";
import avatar from "../assets/images/avatar.jpg";
import "./HomePage.css";
import { useNavigate } from "react-router-dom";

function HomePage() {
  const navigate = useNavigate();

  const navigateToAbout = () => {
    navigate("/about");
  };

  return (
    <div className="HomePage">
      <header className="HomePage-hero">
        <div className="HomePage-hero-relative"> {/* stars and text */}
          <picture>
            <source media="(min-width: 768px)" srcSet={stars} />
            <img src={stars} className="stars" alt="stars" />
          </picture>
          <div className="HomePage-hero-text">
            <h1>Skylar Fountain</h1>
            <h2>YA FANTASY WRITER</h2>
            <button onClick={navigateToAbout}>ABOUT ME</button>
          </div>
        </div>
        <picture>
          <source media="(min-width: 768px)" srcSet={book} />
          <img src={book} className="stars" alt="stars" />
        </picture>
        <section className="HomePage-body">
          <picture>
            <source media="(min-width: 768px)" srcSet={avatar} />
            <img src={avatar} className="avatar" alt="avatar" />
          </picture>
          <div className="HomePage-body-text">
            <h1>Welcome</h1>
            <p>
              My name is Skylar, and I write Young Adult Fantasy. Check out my
              Substack and Medium posts below if you want to stick around and
              learn more about my WIP, or my Instagram for what I am up to these
              days.
            </p>
            <div className="HomePage-body-buttons">
              <button
                onClick={() =>
                  window.open("https://skylarfountain.substack.com/")
                }
              >
                SUBSTACK
              </button>
              <button
                onClick={() =>
                  window.open("https://medium.com/@skylarnfountain")
                }
              >
                MEDIUM
              </button>
              <button
                onClick={() =>
                  window.open("https://www.instagram.com/fountainoffantasy/")
                }
              >
                INSTAGRAM
              </button>
            </div>
          </div>
        </section>
      </header>
    </div>
  );
}

export default HomePage;
