import "./About.css"; // Import the CSS file for styling

function About() {
  return (
    <div className="HomePage">
      <div className="HomePage-header">
        <div className="AboutPage-container">
          <header className="AboutPage-header">
            <h1>About Me</h1>
          </header>
          <section className="AboutPage-content">
            <h2>My Journey</h2>
            <p>
              I am a recent graduate of Drexel’s MFA Creative Writing program.
              When I’m not writing, I work on coding projects (including this
              website!), craft, and play video games.
            </p>
            <h2>Inspirations</h2>
            <p>
              My favorite authors are Brandon Mull and J.R Tolkein. I am
              inspired by their world-building and character development. I hope
              to create a world as rich and detailed as theirs one day.
            </p>
            <h2>Published Works</h2>
            <p>
              Nothing at the moment! But I am working on a YA Fantasy novel that
              I am excited to share with the world. Eventually.
            </p>
          </section>
          <nav>
            <a href="/" className="AboutPage-backLink">
              Back to Home
            </a>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default About;
